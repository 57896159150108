import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { createRef, ref, Ref } from 'lit/directives/ref.js';

import '@shoelace-style/shoelace/dist/components/icon/icon';
import '@shoelace-style/shoelace/dist/components/checkbox/checkbox';
import SlCheckbox from '@shoelace-style/shoelace/dist/components/checkbox/checkbox';
import '@shoelace-style/shoelace/dist/components/card/card';

import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js';
setBasePath('/dist/shoelace');

import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library.js';
registerIconLibrary('material', {
    resolver: name => {
        const match = name.match(/^(.*?)(_(round|sharp))?$/);
        if (!match) {
            console.log("invalid icon:", name);
            return "";
        }
        return `https://cdn.jsdelivr.net/npm/@material-icons/svg@1.0.5/svg/${match[1]}/${match[3] || 'outline'}.svg`;
    },
    mutator: svg => svg.setAttribute('fill', 'currentColor')
});

// Client ID and API key from the Developer Console
var CLIENT_ID = '159886909790-arglpaqc8m203o5lludsk6s3be4omcjo.apps.googleusercontent.com';
var API_KEY = 'AIzaSyBHrEyb23BxByCe6oqFuTRGhfxgUW35STQ';

// Array of API discovery doc URLs for APIs used by the quickstart
var DISCOVERY_DOCS = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
var SCOPES = "https://www.googleapis.com/auth/spreadsheets.readonly";

const refSheetDate = Date.parse('30 December, 1899, 00:00:00 UTC');

function parseSheetDate(serial: number): Date {
    const d = new Date(refSheetDate);
    d.setDate(d.getDate() + serial);
    return d;
}

function sameDay(d1: Date, d2: Date): boolean {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}

type Entry = {
    when: Date;
    lunch: string;
    dinner: string;
}

@customElement('app-main')
export class AppMain extends LitElement {
    // Define scoped styles right with your component, in plain CSS
    static styles = css`
        :host {
            background-color: #f5f5f5;
            display: grid;
            margin: 0;
            padding: 0;
            height: 100%;
            grid-template-columns: 100fr;
            grid-template-rows: 2em 100fr;
        }

        #header {
            /*grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;*/
            width: 100%;
            height: 2em;
            background-color: #437453;
            position: fixed;
            top: 0px;
        }

        #main {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
            background-color: #f5f5f5;
        }

        #main table {
            width: 100%;
        }

        th {
            border-top: black;
            border-top-style: dotted;
            border-top-width: thin;
        }
    `;

    render() {
        const f = new Intl.DateTimeFormat('fr-FR', { weekday: 'long', month: 'short', day: '2-digit', year: 'numeric' });
        return html`
            <div id="header">
            ${this.isSignedIn ?
                html`<button @click=${this.handleSignoutClick}>Sign Out</button>`
                : html`<button @click=${this.handleAuthClick}>Authorize</button>`
            }
            </div>
            <div id="main">
                <table>
                ${this.entries.map((entry) => html`
                    <tr><th>${f.format(entry.when)}</th></tr>
                    <tr><td>${entry.lunch}</td></tr>
                    <tr><td>${entry.dinner}</td></tr>
                `)}
                </table>

            </div>
        `;
    }

    @property({ attribute: false })
    isSignedIn: boolean = false;

    @property({ attribute: false })
    entries: Entry[] = [];

    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback()
    }

    disconnectedCallback() {
        super.disconnectedCallback()
    }

    firstUpdated(_changedProperties: any) {
        super.firstUpdated(_changedProperties);
    }

    initClient() {
        gapi.client.init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES
        }).then(() => {
            // Listen for sign-in state changes.
            (gapi as any).auth2.getAuthInstance().isSignedIn.listen((v: boolean) => this.updateSigninStatus(v));

            // Handle the initial sign-in state.
            this.updateSigninStatus((gapi as any).auth2.getAuthInstance().isSignedIn.get());
        }, function (error) {
            console.log(JSON.stringify(error, null, 2));
        });
    }

    updateSigninStatus(isSignedIn: boolean) {
        console.log("signedin", isSignedIn);
        this.isSignedIn = isSignedIn;
        if (isSignedIn) {
            console.log("signed in", gapi.client);
            this.doStuff();
        } else {
            console.log("signed off");
        }
    }

    handleAuthClick() {
        (gapi as any).auth2.getAuthInstance().signIn();
    }

    handleSignoutClick() {
        (gapi as any).auth2.getAuthInstance().signOut();
    }

    doStuff() {
        (gapi.client as any).sheets.spreadsheets.values.get({
            spreadsheetId: '1bKNuCMh4Mxjnci_zJHzJr0rFf94EtVZeM61rfhWjWD4',
            range: 'Repas!A2:C',
            valueRenderOption: 'UNFORMATTED_VALUE',
        }).then((resp: any) => {
            const entries: Entry[] = [];
            const f = new Intl.DateTimeFormat('fr-FR', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' });
            const today = new Date();
            for (let i = 0; i < resp.result.values.length; i++) {
                const row = resp.result.values[i];
                const entry = {
                    when: parseSheetDate(row[0]),
                    lunch: row[1],
                    dinner: row[2],
                };
                entries.push(entry)
                if (sameDay(today, entry.when)) {
                    console.log(f.format(entry.when), entry.lunch, entry.dinner);
                }
            }
            this.entries = entries;
        }, (resp: any) => {
            console.error(JSON.stringify(resp));
        });
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "app-main": AppMain,
    }
}


// Setup base document.
const htmlElt = document.body.parentElement!;
htmlElt.style.height = '100%';
document.body.style.height = '100%';
document.body.style.margin = '0';
const appMain = document.body.appendChild(document.createElement("app-main")) as AppMain;

// Load up the Google API.
// From https://developers.google.com/sheets/api/quickstart/js
const script = document.body.appendChild(document.createElement("script")) as HTMLScriptElement;
script.defer = true;
script.async = true;
script.src = "https://apis.google.com/js/api.js";
script.onload = () => {
    gapi.load('client:auth2', () => { appMain.initClient() });
};